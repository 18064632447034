@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@500&display=swap");

.note-icon {
  margin-right: -2px;
  transform: rotate(320deg);
}
.note-div {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 2px;
}
.note-button {
  background: rgb(99 179 150);
  border: none;
  border-radius: 11px;
  display: flex;
  align-items: center;
  height: 32px;
  position: absolute;
  margin: 8px;
  top: 0;
}

.note-input {
  background-color: #f4f6fa;
  border: none;
  height: 45px;
  border-radius: 15px;
  padding-right: 50px;
  background-image: none;
}
.note-input:focus {
  background-color: #f4f6fa;
}
.note-delete {
  font-size: 18px;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jobApplicationForm .form-group {
  flex: 1;
}

/* .heart {
  font-size: 150px;
  color: #e00;
  animation: beat 0.25s infinite alternate 1s;
  transform-origin: center;
}

Heart beat animation
@keyframes beat {
  to {
    transform: scale(1.4);
  }
} */

.fa-user-alt-icon {
  background-color: #c9c9c9;
  padding: 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scrollBar {
  max-height: 660px;
  overflow-y: scroll;
}
.scrollBar::-webkit-scrollbar {
  display: none;
}
.invalid-feedback {
  font-size: 10px;
}
.placeholder-muted::-webkit-input-placeholder {
  color: #adadad;
}
@media screen and (max-width: 768px) {
  .sm\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .sm\:px-0 {
    padding-left: 0 !important ;
    padding-right: 0 !important;
  }
  .card {
    border-radius: 1rem;
  }
  .sm\:p-1 {
    padding: 10px !important;
  }
  .sm\:d-none {
    display: none !important;
  }
  .page-content {
    padding-top: 10px;
    min-height: calc(100vh - 66px);
    padding-bottom: 60px;
  }
  .skeleton-card span {
    width: 47%;
    margin-bottom: 8px;
  }
  .skeleton-profile span:first-child {
    width: 80%;
  }
}
.boxshadow-none {
  box-shadow: none !important;
}
