.row.gutter_zero {
    --bs-gutter-x: 0 !important;
}

#nav-black {
    margin-bottom: -2rem !important;
}


#mainBgImg {
    position: absolute;
    height: 42rem;
    object-fit: cover;
}

.nav-sizing {
    flex: 0 0 auto;
    width: 100%;
}

.marginLg {
    margin-right: 1.5rem !important;
}

.marginMd {
    margin-right: 0.5rem !important;
}

/* cool icons */
.base-logos {
    filter: grayscale(100);
}

.base-logos:hover {
    filter: grayscale(0);
}

/* cool icons */

.fullkit {
    overflow-x: hidden;
}

.base-logos-first {
    width: 100px;
}

.base-logos-second {
    width: 170px;
}

.base-logos-third {
    width: 130px;
}

.marg-right {
    justify-content: space-between;
}

.headerBg {
    background: rgba(255, 255, 255, 0.38);
    filter: blur(8px);
    -webkit-filter: blur(8px);
}

.my-gradiant {
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0.26096376050420167) 94%);
}

#navbar-transparent {
    /* background-color: rgba(255, 255, 255, 0.295) !important; */

    background-color: #f6f6f6d4 !important;
    max-height: 5rem;
}

.btn-primary-outline {
    color: #fff !important;
    font-weight: 500 !important;
    border: 1px solid #fff !important;
    border-radius: 5px !important;
}

.font-weight-bold {
    font-weight: 500 !important;
}

.font-weight-bold-sign {
    font-weight: 500 !important;
    color: #0073cb !important;
}

.mainQuotes {
    margin-bottom: 20px !important;
    margin-top: 18rem !important;
    border-bottom: .12rem solid #fff !important;
    border-radius: 1.2px !important;
}

.mainQuotes h1 {
    font-size: 4rem !important;
}

.main-bottom-btn {
    font-size: 1rem !important;
    padding: 5px 2rem 0px 2rem !important;
    font-weight: 500;
    white-space: nowrap;
    display: flex !important;
    align-items: center !important;
    padding-bottom: 10px !important;
}

.h-full {
    height: 100% !important;
}

.w-full {
    width: 100% !important;
}

.buttonNewWrap {
    padding: 0.6rem !important;
    display: flex;
    align-items: flex-end;
}

.buttonNewWrap a {
    margin: 0 5px;
}

.whitespace {
    white-space: nowrap !important;
}

.Row {
    position: relative;
}

.imgA1 {

    color: #0078d4 !important;
    background-color: #f8f8fb !important;
    box-shadow: 0 0 0 0 #f8f8fb !important;
}

.imgA1 h1 {
    color: #0078d4 !important;
}

.imgA1 .cntnr {
    margin-top: 6rem;
}


.text-big {
    font-size: 4rem;
}

.text-small {
    font-size: 1rem;
}

.splitter {
    padding-bottom: 14rem;
}

.carausalCard {
    background-color: #dcebfa;
    padding-bottom: 8rem;
}

.rcontainer {
    margin: 0 auto;
    margin-top: -6rem;
    position: relative;
    width: 80%;
    height: 65vh;
    padding: 0 0 0 0;
    padding-bottom: 38%;
    user-select: none;
    border-radius: 10px;
    background-color: #fff;
}

.slick-prev {
    left: -70px;
}

.resizer {
    max-width: 100rem !important;
    margin-bottom: 2rem;
}

.rcontainer .slide_img {
    width: 100%;
    ;
    height: 100%;
}

.rcontainer .slide_img img {
    width: inherit;
    height: inherit;
}


.prev,
.next {
    width: 12%;
    height: inherit;
    position: absolute;
    top: 0;
    color: rgba(244, 244, 244, .9);
    z-index: 99;
    transition: .45s;
    cursor: pointer;
    text-align: center;
}

.next {
    right: 0;
}

.prev {
    left: 0;
}

label span {
    position: absolute;
    font-size: 100pt;
    top: 50%;
    transform: translateY(-50%);
}

.prev:hover,
.next:hover {
    transition: .3s;
    color: #ffffff;
}

.rcontainer #nav_slide {
    width: 100%;
    top: 30rem;
    bottom: 0;
    height: 11px;
    position: absolute;
    text-align: center;
    z-index: 99;
    cursor: default;
}

#nav_slide .dots {
    top: -2px !important;
    color: #fff;
    margin: 0 4px;
    position: relative;
    display: inline-block;
    background-color: rgba(20, 163, 206, 0.911);
    transition: .4s;
}

#nav_slide .dots:hover {
    cursor: pointer;
    color: #000;
    background-color: rgba(255, 255, 255, 0.9);
    transition: .25s
}

.slide_img {
    z-index: -1;
}


#i1:checked~#one,
#i2:checked~#two,
#i3:checked~#three,
#i4:checked~#four,
#i5:checked~#five {
    z-index: 9;
    animation: scroll 1s ease-in-out;
}

#i1:checked~#nav_slide #dot1,
#i2:checked~#nav_slide #dot2,
#i3:checked~#nav_slide #dot3,
#i4:checked~#nav_slide #dot4,
#i5:checked~#nav_slide #dot5 {
    background-color: rgba(255, 255, 255, .9);
    color: #000;
}

.slick-arrow {
    transform: scale(4) !important;
}

.slick-next {
    padding-left: 15px !important;
    right: 0 !important;
}

.slick-prev {
    left: -50px !important;
}

@keyframes scroll {
    0% {
        opacity: .4;
    }

    100% {
        opacity: 1;
    }
}


#nav-black {
    background-color: #1781bb !important;
}


/* media queries */

@media screen and (min-width: 1200px) {
    .main-small-heading {
        padding: 0 3rem 0 3rem !important;
    }

    .mainQuotes {
        padding: 0 3rem 0 3rem !important;
    }

    .main-sub-heading {
        padding: 0 3rem 0 3rem !important;
    }

    #nav_slide .dots {
        top: -4rem !important;
    }

    .marginLg {
        margin-right: 1rem !important;
    }

    .marginMd {
        margin-right: 0.5rem !important;
    }

    .slick-arrow {
        transform: scale(3) !important;

    }

    /* .slick-next {
        padding-left: 1px !important;
    } 
    
    .slick-prev {
        left: -30px !important;
    }  */
}

@media screen and (max-width: 990px) {
    .main-small-heading {
        padding: 0 3rem 0 3rem !important;
    }

    .mainQuotes {
        padding: 0 3rem 0 3rem !important;
    }

    .main-sub-heading {
        padding: 0 3rem 0 3rem !important;
    }

    #nav_slide .dots {
        top: -4rem !important;
    }

    .marginLg {
        margin-right: 1rem !important;
    }

    .marginMd {
        margin-right: 0.5rem !important;
    }

    .slick-arrow {
        transform: scale(3) !important;

    }

    .slick-next {
        padding-left: 1px !important;
    }

    .slick-prev {
        left: -30px !important;
    }
}

@media screen and (max-width: 768px) {
    #nav_slide .dots {
        top: -9rem !important;
    }

    .imgA1 h1 {
        font-size: 3.1rem;
    }

    .imgA1 {
        width: 28rem !important;
        height: 20rem;
    }

}

@media screen and (max-width: 720px) {
    .mainQuotes h1 {
        font-size: 3rem !important;
    }

    .main-sub-heading h3 {
        font-size: 1.2rem;
    }



    #nav_slide .dots {
        top: -10rem !important;
    }

    .base-logos-first {
        width: 80px;
    }

    .base-logos-second {
        width: 130px;
    }

    .base-logos-third {
        width: 100px;
    }


}

@media screen and (max-width: 685px) {
    .mainQuotes h1 {
        font-size: 3rem !important;
    }

    .main-sub-heading h3 {
        font-size: 1.2rem;
    }



    #nav_slide .dots {
        top: -11rem !important;
    }


}

@media screen and (max-width: 640px) {
    #nav_slide .dots {
        top: -12rem !important;
    }

    .imgA1 h1 {
        font-size: 2.7rem;
    }

    .imgA1 {
        height: 30rem !important;
    }

    .slick-next {
        display: none !important;
    }

    .slick-prev {
        display: none !important;
    }
}

@media screen and (max-width: 575px) {
    #nav_slide .dots {
        top: -13rem !important;
    }

    .nav-sizing {
        padding: 0 1rem 0 1rem;
    }

}

@media screen and (max-width: 480px) {
    .mainQuotes h1 {
        font-size: 2rem !important;
    }

    .main-sub-heading h3 {
        font-size: 1rem;
    }

    .rcontainer {
        width: auto;
        height: 14rem;
    }

    #nav_slide .dots {
        top: -12rem !important;
        width: 3.7rem;
        height: 3rem;
        font-size: 8px;
    }

    #one,
    #two,
    #three,
    #four,
    #five {
        height: 18rem;
    }


}

@media screen and (max-width: 420px) {
    .mainQuotes h1 {
        font-size: 2rem !important;
    }

    .main-sub-heading h3 {
        font-size: .8rem;
    }


    #nav_slide .dots {
        top: -12rem !important;
        width: 3rem;
        height: 2rem;
        font-size: 6px;
    }

    #one,
    #two,
    #three,
    #four,
    #five {
        height: 17rem;
    }

}

@media screen and (max-width: 395px) {
    .font-weight-bold {
        font-size: .5rem;
    }

    .text-adjust {
        font-size: .5rem !important;
    }

    .main-small-heading {
        padding: 0 .4rem 0 .4rem !important;
    }

    .mainQuotes {
        padding: 0 0px 0 8px !important;
    }

    .mainQuotes h1 {
        font-size: 2.5rem !important;
        text-align: start;
    }

    .main-sub-heading {
        padding: 0 25px 0 25px !important;
    }

    .main-sub-heading h3 {
        font-size: .6rem;
    }

    .main-bottom-btn {
        padding: 0 10px 4px 10px !important;
    }

    .text-small {
        font-size: 0.6rem;
    }


    #nav_slide .dots {
        top: -18rem !important;
        width: 3rem;
        height: 2rem;
        font-size: 6px;
    }

    .base-logos-first {
        width: 50px;
    }

    .base-logos-second {
        width: 90px;
    }

    .base-logos-third {
        width: 60px;
    }

    .rcontainer {
        width: auto;
        height: 12rem;
    }

    #one,
    #two,
    #three,
    #four,
    #five {
        height: 12rem;
    }

    .carausalCard {
        padding: 0 .5rem 2rem .5rem;
    }

}

@media screen and (max-width: 390px) {
    .font-weight-bold {
        font-size: .5rem;
    }

    .text-adjust {
        font-size: .5rem !important;
    }

    .main-small-heading {
        padding: 0 .4rem 0 .4rem !important;
    }

    .mainQuotes {
        padding: 0 0px 0 8px !important;
    }

    .mainQuotes h1 {
        font-size: 2.5rem !important;
        text-align: start;
    }

    .main-sub-heading {
        padding: 0 25px 0 25px !important;
    }

    .main-sub-heading h3 {
        font-size: .6rem;
    }

    .main-bottom-btn {
        padding: 0 10px 4px 10px !important;
    }

    .text-small {
        font-size: 0.6rem;
    }



    #nav_slide .dots {
        top: -18rem !important;
        width: 3rem;
        height: 2rem;
        font-size: 6px;
    }

    .base-logos-first {
        width: 50px;
    }

    .base-logos-second {
        width: 90px;
    }

    .base-logos-third {
        width: 60px;
    }

    #one,
    #two,
    #three,
    #four,
    #five {
        height: 14rem;
    }

    .carausalCard {
        padding-bottom: 2rem;
    }

}

@media screen and (max-width: 384px) {
    .font-weight-bold {
        font-size: .5rem;
    }

    .text-adjust {
        font-size: .5rem !important;
    }

    .main-small-heading {
        padding: 0 .4rem 0 .4rem !important;
    }

    .mainQuotes {
        padding: 0 0px 0 8px !important;
    }

    .mainQuotes h1 {
        font-size: 2.5rem !important;
        text-align: start;
    }

    .main-sub-heading {
        padding: 0 25px 0 25px !important;
    }

    .main-sub-heading h3 {
        font-size: .6rem;
    }

    .main-bottom-btn {
        padding: 0 10px 4px 10px !important;
    }

    .text-small {
        font-size: 0.6rem;
    }


    #nav_slide .dots {
        top: -18rem !important;
        width: 3rem;
        height: 2rem;
        font-size: 6px;
    }

    .base-logos-first {
        width: 50px;
    }

    .base-logos-second {
        width: 90px;
    }

    .base-logos-third {
        width: 60px;
    }

    #one,
    #two,
    #three,
    #four,
    #five {
        height: 14rem;
    }

    .carausalCard {
        padding-bottom: 2rem;
    }

}



@media screen and (max-width: 365px) {
    .font-weight-bold {
        font-size: .5rem;
    }

    .text-adjust {
        font-size: .5rem !important;
    }

    .main-small-heading {
        padding: 0 1rem 0 1rem !important;
    }

    .mainQuotes h1 {
        font-size: 2.2rem !important;
    }

    .main-sub-heading h3 {
        font-size: .6rem;
    }


    #nav_slide .dots {
        top: -18rem !important;
        width: 3rem;
        height: 2rem;
        font-size: 6px;
    }

    .base-logos-first {
        width: 60px;
    }

    .base-logos-second {
        width: 100px;
    }

    .base-logos-third {
        width: 70px;
    }

    #one,
    #two,
    #three,
    #four,
    #five {
        height: 16rem;
    }

    .carausalCard {
        padding-bottom: 3rem;
    }

}

@media screen and (max-width: 320px) {
    .font-weight-bold {
        font-size: .5rem;
    }

    .text-adjust {
        font-size: .5rem !important;
    }

    .main-small-heading {
        padding: 0 .4rem 0 .4rem !important;
    }

    .mainQuotes {
        padding: 0 0px 0 10px !important;
    }

    .mainQuotes h1 {
        font-size: 2.3rem !important;
        text-align: start;
    }

    .main-sub-heading {
        padding: 0 25px 0 25px !important;
    }

    .main-sub-heading h3 {
        font-size: .6rem;
    }

    .main-bottom-btn {
        padding: 0 10px 4px 10px !important;
    }

    .text-small {
        font-size: 0.6rem;
    }


    #nav_slide .dots {
        top: -18rem !important;
        width: 3rem;
        height: 2rem;
        font-size: 6px;
    }

    .base-logos-first {
        width: 50px;
    }

    .base-logos-second {
        width: 90px;
    }

    .base-logos-third {
        width: 60px;
    }

    #one,
    #two,
    #three,
    #four,
    #five {
        height: 15rem;
    }

    .carausalCard {
        padding-bottom: 3rem;
    }

}


@media screen and (min-width: 970px) {
    .me {
        display: none;
    }
}


.imgA1 {
    padding: 5rem !important;
}

@media screen and (max-width:992px) {
    .md\:flxe-col {
        flex-direction: column-reverse !important;
    }

    .imgA1 {
        width: 100% !important;
    }

    .md\:w-full {
        width: 100% !important;
    }
}

@media screen and (max-width:580px) {
    .imgA1 {
        padding: 1rem !important;
    }

    .splitter {
        padding-bottom: 4rem !important;
    }

    .imgA1 h1 {
        font-size: 38px !important;
    }
}

@media screen and (max-width:1024px) {
    .imgA1 {
        padding: 1rem !important;
    }

    .splitter {
        padding-bottom: 4rem !important;
    }

    .imgA1 h1 {
        font-size: 50px;
    }
}