h1,
h2,
h3,
h4,
h5,
h6 {
}

section {
  padding: 60px 0;
  min-height: 100vh;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a,
a:active,
a:focus {
  color: #6f6f6f;
  text-decoration: none;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  -ms-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}

.footer-section {
  background-color:#ffffff !important;
  position: relative;
  overflow: hidden;
  z-index: 9;
}
.footer-section:before {
  content: "";
  position: absolute;
  top: -146%;
  left: -18%;
  width: 44%;
  height: 257%;
  transform: rotate(54deg);
  /* background-color: rgb(31, 47, 64); */
  background-color:#ffffff !important;

  -webkit-transform: rotate(54deg);
  -moz-transform: rotate(54deg);
  -ms-transform: rotate(54deg);
  -o-transform: rotate(54deg);
  z-index: -10;
}
.footer-section:after {
  position: absolute;
  content: "";
  /* background-color: rgb(31, 47, 64); */
  background-color:#ffffff !important;

  top: -24%;
  right: 4%;
  width: 26%;
  height: 264%;
  transform: rotate(44deg);
  -webkit-transform: rotate(44deg);
  -moz-transform: rotate(44deg);
  -ms-transform: rotate(44deg);
  -o-transform: rotate(44deg);
  z-index: -10;
}
.footer-top {
  padding-top: 96px;
  padding-bottom: 50px;
}
.color-black{
  color: #9c2592 !important;
}
.footer-top p,
.company-footer-contact-list li {
  color: #000000;
}
.company-footer-contact-list {
  margin-top: 10px;
  padding: 0 !important;

}
.company-footer-contact-list li {
  display: flex;
  display: -webkit-flex;
  align-items: center;
}
.company-footer-contact-list li + li {
  margin-top: 5px;
}
.company-footer-contact-list li i {
  margin-right: 10px;
  font-size: 20px;
  display: inline-block;
}

.footer-top .site-logo {
  margin-bottom: 25px;
  display: block;
  max-width: 230px;
}
.widget{
    max-width: 220px ;
}
.widget-title {
  text-transform: capitalize;
}
.footer-top .widget-title {
  color: #000000;
  margin-bottom: 40px;
}
.courses-link-list li + li {
  margin-top: 10px;
}
.courses-link-list li a {
  color: #000000;
  text-transform: capitalize;
  font-family: var(--para-font);
  font-weight: 400;
}
.courses-link-list li a:hover {
  color: #ffb606;
}
.courses-link-list li i {
  margin-right: 5px;
}
.footer-top .small-post-title a {
  font-family: var(--para-font);
  color: #000000;
  font-weight: 400;
}
.post-date{
    color:black !important ;
    text-decoration: none;
    
}
.small-post-item .post-date {
  /* color: #ffb606; */
  color: #2d3792;
  margin-bottom: 3px;
  font-family: var(--para-font);
  font-weight: 400;
}
.small-post-list li + li {
  margin-top: 30px;
}
.news-letter-form {
  margin-top: 15px;
}
.news-letter-form input {
  width: 100%;
  padding: 12px 25px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none;
}
.news-letter-form input[type="submit"] {
  width: auto;
  border: none;
  background-color: #ffb606;
  padding: 9px 30px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  color: #000000;
  margin-top: 10px;
}
.footer-bottom {
  padding: 13px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.149);
}
.copy-right-text {
  color: #000000;
}
.copy-right-text a {
  /* color: #ffb606; */
  color: #2d3792;

}
.terms-privacy li + li {
  margin-left: 30px;
}
.terms-privacy li a {
  color: #000000;
  position: relative;
}
.terms-privacy li a:after {
  position: absolute;
  content: "-";
  color: #000000;
  display: inline-block;
  top: 0;
  right: -18px;
}
.terms-privacy li + li a:after {
  display: none;
}
.md\:d-none{
display: none !important;
}
@media screen and (max-width:992px) {
    .md\:mt-4{
        margin-top: 2rem !important;
        }
        .md\:d-none{
            display: block !important;
        }
}

@media screen and (max-width:580px) {
    .footer-top{
        padding-top: 20px;
    }
}