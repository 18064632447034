input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
  display: none !important;
}
.osperb-editor {
  background-color: red !important;
  .jodit-status-bar a.jodit-status-bar-link {
    display: none !important;
    height: 150px;
  }
  .jodit-status-bar__item.jodit-status-bar__item-right {
    &::before {
      content: "Powered by osperb";
      position: absolute;
      bottom: 21px;
      right: 25px;
    }
  }
}
.bg-light-success {
  background-color: #34c38f40;
}

.flex-1 {
  flex: 1 !important;
}
.user-chat {
  width: 90% !important;
  margin: auto;
}
.note-form {
  .form-group {
    width: 100%;
  }
}
.student-details-container {
  overflow-y: scroll;
  height: 70vh;
  &::-webkit-scrollbar {
    display: none;
  }
}
.chat {
  width: 70px;
  height: 70px;
  position: absolute;
  bottom: 5rem;
  right: 1rem;
  z-index: 99;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  padding: 10px;
  img {
    // border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  transition: all ease-in-out 0.3s;
  &:hover {
    transform: scale(1.2);
    .heart {
      animation: none;
    }
  }
  .heart {
    animation: beat 0.25s infinite alternate 2s;
    transform-origin: center;
  }
  @keyframes beat {
    to {
      transform: scale(1.4);
    }
  }
}
.cutsom-input {
  .form-group {
    width: 100%;
  }
}
// .editable-table {
//   tbody {
//     tr {
//       td {
//         width: 200px;
//         height: 65px;
//         &:last-child {
//           width: 150px;
//         }
//       }
//       .table-input {
//         width: 200px;
//         height: 30px;
//         .dropdown {
//           width: 150px;
//         }
//       }
//     }
//   }
// }
.updateStudentProfile ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100px;
  margin-bottom: 10px !important;
}
.updateStudentProfile ul li a i {
  font-size: 20px !important;
  margin-bottom: 10px;
}

.image_wrapper {
  img {
    object-fit: cover;
    border-radius: 5px;
  }
  position: relative;
  max-height: 120px;
  .edit_image_btn {
    display: none;
  }
  .defualt_images {
    filter: grayscale(100%);
  }
  &:hover {
    .shadow {
      position: absolute;
      background-color: #00000061;
      border-radius: 5px;
      height: 100%;
      width: 100%;
      top: 0;
      transition: all 0.3s ease-in-out;
    }
    .edit_image_btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      font-size: 18px;
      display: block;
    }
  }
}

.css-2b097c-container {
  width: 100% !important;
}
.navbar-brand-box {
  text-align: start;
}
.closeIcon {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}
.myScrollCard {
  max-height: 740px;
  overflow-y: scroll;
}
.myScrollCard::-webkit-scrollbar {
  display: none;
}
// .apexcharts-legend {
//   // display: none !important;
// }
.h-240 {
  height: 200px;
}
.text-green {
  color: #34c38f;
}

// custom end

.editor-class {
  height: auto !important;
  min-height: 250px;
}
.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}
.overflow-div {
  overflow-y: scroll;
  // max-height: 100vh;
}
.overflow-div::-webkit-scrollbar {
  display: none;
}
.fc .fc-toolbar-title {
  font-size: 1.3em;
}

.fc-daygrid-event {
  border: none;
}
.selection-cell {
  display: none;
}
.selection-cell-header {
  display: none;
}
#select-cell .selection-cell {
  display: block !important;
}
#select-cell .selection-cell-header {
  display: block !important;
}

// .overflowScroll {
//   // overflow-x: scroll;

// }
.max-h-5::-webkit-scrollbar,
.overflowScroll::-webkit-scrollbar {
  display: none;
}

// .nav-pills .nav-link.active,
// .nav-pills .show > .nav-link {
//   color: #fff;
//   background-color: #444444 !important;
// }

.btn.custom_border_rad {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
}
.custome_select_rad .css-yk16xz-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.createClintBtn {
  padding-right: 0px;
}
.createClintBtnCont {
  display: flex;
  justify-content: center;
  align-items: center;
}
.borderBottomWhite {
  border-bottom: 1px solid #fff;
}
.myWidth30 {
  width: 30% !important;
}
.myWidth20 {
  width: 20% !important;
}
.width-fit {
  width: fit-content !important;
  margin-left: auto;
}
@media print {
  .bg-info-new {
    color: #000 !important;
    background-color: #f8f9fa !important;
    // -webkit-print-color-adjust: exact;
  }
  .bg-light-new {
    background-color: #f8f9fa !important;
    // -webkit-print-color-adjust: exact;
  }
  .print-pb-3 {
    padding-bottom: 2rem;
  }
  .print-pt-3 {
    padding-top: 2rem;
  }
  .print-boxshadow {
    box-shadow: none !important;
  }
  .display-none {
    display: none !important;
  }
  .display-block {
    display: block !important;
  }
  // #header,
  // #footer,
  #nav {
    display: none !important;
  }
}

@media screen and (max-width: 986px) {
  .createClintBtn,
  .createClintBtnCont {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
@media screen and (max-width: 600px) {
  .d-none-smo {
    display: none;
  }
}

.btn-wrap {
  flex-direction: column;
}

@media screen and (max-width: 540px) {
  .sm\:p-0 {
    padding: 0 !important;
  }
  .btn-wrap {
    flex-direction: row;
  }
}
.red {
  color: rgb(70, 193, 86);
}
.center-o {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18 px;
}
.bg-light-gray {
  background-color: #f8f8fb !important;
}
.next-buttom {
  display: flex;
  justify-items: space-between;
}
// .moduls {
//   display: flex;
//   justify-content: first baseline;
// }
.font-size-moduls {
  font-size: 25px;
}
.colors {
  color: rgb(138, 227, 138);
}
.end-o {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.height {
  min-height: 250px;
}
.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.button-settup {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.mar-t {
  margin-top: 10px;
}
.cursor {
  cursor: pointer;
}

.rashid {
  color: #d6d6f2;
}
.btn-flex {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 20px;
}
.custom_select_new .css-2b097c-container {
  max-width: 120px !important;
}
.custom_select_new .select2-selection__control,
.css-1hb7zxy-IndicatorsContainer {
  height: 27px;
  min-height: 27px;
}
.custom_select_new
  .select2-selection__value-container.css-g1d714-ValueContainer {
  margin-top: -3px;
}
.label-edit {
  height: 45px;
  // width: 80px;
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
}
.color {
  color: red;
}
.font {
  font-size: 20px;
}
.colors {
  background: rgb(171, 171, 217);
}
.career-input-field {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}
.moduls-btn {
  color: yellow;
}

.not-paid-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  cursor: not-allowed;
  color: #fff;
  border-radius: 5px;
  min-height: 200px;
  background: rgb(114 114 114 / 50%);
  backdrop-filter: blur(2px);
}
.upload-icons {
  position: absolute;
  bottom: 12px;
  font-size: 16px;
  color: green;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
}
.profile-images {
  .profile-download {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    i {
      box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
      width: 33px;
      text-align: center;
      margin: 0;
      border-radius: 25px;
      color: #ffffff;
    }
  }
  &:hover .profile-download {
    i {
      color: #ffffff;
      background: #00000063;
    }
  }
}
.mobile-nab-bar {
  position: relative;
  display: flex;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  // overflow-x: scroll;
  // overflow-y: hidden;
  justify-content: space-between;
  padding: 10px 0;
  .nav-line {
    height: 5px;
    background: #d7f7ff;
    // background: #e3e3e3;
    position: absolute;
    top: 50%;
    width: -webkit-fill-available;
    transform: translateY(-50%);
    overflow: unset;
    z-index: 0;
    &.active {
      background: #034e60;
    }
  }
  .nav-item {
    .nav-link {
      flex-direction: row;
      border-radius: 30px;
      align-items: center;
      justify-content: center;
      color: rgb(110, 110, 110);
      background-color: #f2f2f2 !important;
      background-color: #d7f7ff !important;
      height: 30px;
      width: 30px !important;
      position: relative;
      z-index: 9;
      // width: max-content !important;
      box-shadow: none !important;
      // background-color: #f2f2f2 !important;
      margin-bottom: 0 !important;
      i {
        margin-bottom: 0;
        margin-right: 5px;
      }
      &.active {
        // background-color: #6ed5ed !important;
        background-color: #034e60 !important;
        color: rgb(255, 255, 255);
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .sm\:w-100 {
    width: 100% !important;
  }
  .sm\:text-white {
    color: #fff !important;
  }
  .sm\:input {
    height: 45px;
    border-radius: 10px;
  }
  .sm\:m-0 {
    margin: 0 !important;
  }
  .vertical-menu {
    top: 66px !important;
  }
  .updateStudentProfile {
    margin-top: 0 !important;
    padding: 10px;
  }
  .sm\:pt-70 {
    padding-top: 70px !important;
  }
  .sm\:pb-0 {
    padding-bottom: 0px !important;
  }
  .sm\:mb-0 {
    margin-bottom: 0px !important;
  }
  .sm\:mt-1 {
    margin-top: 10px !important;
  }
  .sm\:mt-4 {
    margin-top: 40px;
  }
  .mobile-overflow-vissible {
    overflow: visible;
  }
  .glass-card {
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.18);
    &.border-white {
      border: 1px solid rgba(255, 255, 255, 0.604) !important;
    }
  }
  .text-darkGreen {
    color: #034e60 !important;
  }
  .sm\:fw-600 {
    font-weight: 600;
  }
  .blue-gradient {
    background: linear-gradient(
      90deg,
      rgba(33, 147, 176, 1) 0%,
      rgba(109, 213, 237, 1) 94%
    );
    // background: linear-gradient(
    //   266deg,
    //   rgba(72, 98, 204, 1) 0%,
    //   rgba(107, 172, 226, 1) 94%
    // );
  }
  .box-shadow-none {
    box-shadow: none !important;
  }
  .sm\:bg-transparent {
    background-color: transparent;
  }
  .main-content {
    // background: linear-gradient(
    //   90deg,
    //   rgba(33, 147, 176, 1) 0%,
    //   rgba(109, 213, 237, 1) 94%
    // );
    min-height: calc(100vh - 62px);
  }

  .mobile-ui-form {
    .label-mt-0 {
      .form-group {
        label {
          margin-top: 0 !important;
        }
      }
    }
    label {
      color: #ffffff !important;
      font-weight: 100;
      margin-top: 10px;
    }
    input {
      height: 50px;
      border-radius: 15px;
    }
    textarea {
      border-radius: 15px;
      min-height: 80px;
    }
    .submit-btn {
      background-color: #034e60 !important;
      border-color: #034e60 !important;
      height: 45px;
      border-radius: 15px;
    }
    .mobile-nav-button {
      button {
        background-color: #034e60;
        border-color: #034e60;
        &.btn-info.disabled,
        &.prev-b {
          border: 1px solid #034e60;
          background: transparent;
        }
      }
    }
    .mobile-submit {
      background-color: #034e60;
      border-color: #034e60;
      width: 100% !important;
    }
    .select-option-tag {
      select {
        height: 50px;
        border-radius: 15px;
      }
    }
    .mobile-module-add {
      height: 45px;
      border-radius: 15px;
      background: #034e60;
      color: #fff;
      border: 1px solid #034e60;
    }
    .module_close {
      position: absolute;
      right: -8px;
      top: -8px;
      color: #034e60;
    }
  }
  .MobileNav {
    position: fixed;
    bottom: 10px;
    left: 50%;
    width: 95%;
    margin: auto;
    height: 50px;
    z-index: 9999;
    padding: 5px 10px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-around;
    transition: all ease-in-out 0.3s;
    transform: translateX(-50%);
    border-radius: 15px;
    background: rgb(255 255 255);
    // background: rgb(108 211 235 / 30%);
    box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
    backdrop-filter: blur(15.5px);
    border: 1px solid rgba(255, 255, 255, 0.18);

    .mobile_nav_item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 70px;
      .icon-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      &.active {
        color: #fff !important;
        span {
          display: none;
        }
        .icon-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background: #034e60;
          width: 38px;
          height: 38px;
        }
      }
      svg {
        font-size: 22px;
        &.notification {
          color: #ad1457;
          position: absolute;
          top: -4px;
          right: -8px;
        }
      }
      span {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
  .logout-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    p {
      color: #9c9c9c;
    }
    .img-wrap {
      margin-top: 2rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      img {
        height: 300px;
      }
    }
    .logout-text {
      h5 {
        font-size: 22px;
        color: #034e60;
      }
      p {
        margin: 0;
        padding: 0;
      }
    }
    button {
      border-radius: 20px;
    }
  }
  .mobile-student-view {
    margin-top: 20px;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .upload-icons {
    bottom: -6px;
    right: -8px;
  }
  .mobile-attached-file {
    button {
      &.isActive {
        background: #034e60 !important;
      }
      border: none !important;
      padding: 5px;
      border-radius: 12px;
    }
  }
  .mobile-chat {
    margin-top: 0 !important;
    width: 100% !important;
    .card {
      border-radius: 0 !important;
      margin-bottom: 0 !important;
      height: 100vh;
      background-color: #f9f9f9 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      h6 {
        white-space: nowrap;
      }
      // div {
      //   &:nth-child(2) {
      //     height: 100%;
      //   }
      // }
      .chat-input-section {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #ffffff;
        z-index: 99999;
        height: 65px;
      }
      .chat-header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: #ffffff;
        z-index: 99999;
      }
      .border-bottom {
        height: fit-content;
        border-bottom: none !important;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }
      .chat-conversation {
        height: 100% !important;
        padding-top: 30px;
        background-color: #f9f9f9;
      }
    }

    .chat-input-section {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border-top: none !important;
    }
  }
  .job-card {
    .job {
      background-color: #f2f2f2;
      border-radius: 5px;
      padding: 10px 18px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border: 1px solid #f2f2f2 !important;
    }
  }
  .not-paid-modal {
    backdrop-filter: blur(2px);
    background: rgba(255, 255, 255, 0.25);
  }
  .sm\:text-white {
    color: #ffffff !important;
  }
}

.sp-heading {
  color: rgb(255 252 255 / 71%) !important;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 1.5px;
}
.sp-des {
  color: #fff !important;
  // font-weight: 100;
  font-weight: 500;
  font-size: 13.5px;
}
.numbers-order {
  width: 40px;
  height: 40px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d7f7ff;
  border-radius: 50%;
  p {
    margin: 0;
    padding: 0;
    font-family: "Noto Sans Display", sans-serif;
    font-weight: 500;
  }
  .mobile-verifiy {
    .card {
      box-shadow: none;
      background: transparent;
    }
  }
}

.date-picker-input {
  .DatePicker {
    width: 100%;
    .DatePicker__input {
      width: 100%;
      padding: 0 10px;
      height: 36px;
      border: 1px solid #ced4da;
      border-radius: 5px;
      text-align: start;
      z-index: 0;
      @media screen and (max-width: 768px) {
        height: 50px;
        border-radius: 15px;
      }
    }
  }
}

.isNoInternshipQuestion {
  width: 55%;
  &.full {
    width: 100%;
  }
}

.search-box-1 {
  .form-control {
    border-radius: 30px;
    height: 38px;
  }
}

@media screen and (max-width: 488px) {
  .mobile-ui-form input {
    &.myCheckBox {
      height: 15px;
    }
  }
  .isNoInternshipQuestion {
    width: 100%;
    color: #ffffffe0;
  }
}
